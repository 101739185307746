import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth-context";
import { Navigate } from "react-router-dom";
import axios from "axios";

const LoginPage = () => {
  const authValue = useAuth();
  const { loginHandler, loginLoading, otpHandler } = authValue;

  const [loading, setLoading] = useState(false);

  // otp states
  const [loginOTP, setLoginOTP] = useState(false);
  const [OTPRecieved, setOTPRecieved] = useState(false);

  const [seconds, setSeconds] = useState(300);
  const [isActive, setIsActive] = useState(false);

  const [register, setRegister] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
        }, 1000);
      } else {
        setSeconds(0);
        setIsActive(false);
      }
    } else if (!isActive && seconds !== 300) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  const resendOTP = (contact) => {
    axios
      .post(`/apiV1/generate-otp/`, { contact: contact, method: "login" })
      .then((res) => {
        setOTPRecieved((prev) => true);
        setSeconds(300);
        setIsActive(true);
        message.success("OTP sent");
      })
      .catch((err) => {
        notification.error({
          message: err.response.data?.message ?? "Something went wrong!",
        });
      });
  };

  const onFinish = (values) => {
    if (loginOTP) {
      if (OTPRecieved) {
        if (values?.otp) {
          let authorized1 = false;
          axios
            .post(`apiV1/verify-otp/`, {
              contact: values?.contact,
              otp: values?.otp,
              method: "login",
            })
            .then((response) => {
              authorized1 = true;
              const token = response?.data?.token?.toString();
              const expirationDate = new Date(
                new Date().getTime() + 3600 * 10000
              );
              localStorage.setItem("token", token);
              localStorage.setItem("expirationDate", expirationDate);
              otpHandler(token);
            })
            .catch((error) => {
              message.error(
                `${error.response.data?.message ?? "Something went wrong!"}`
              );
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        if (values?.contact) {
          resendOTP(values?.contact);
        }
      }
    } else {
      loginHandler(values);
    }
  };

  const onRegister = (values) => {
    console.log(values, "values");
    if (values?.password !== values.confirm_password) {
      message.error("Confirm Password is not same as Password");
      return;
    }
    values.roles = 8;
    axios
      .post("apiV1/user/", values)
      .then((res) => {
        console.log(res?.data);
        message.success("User successfully Created");
        loginHandler(values);
      })
      .catch((err) => {
        console.log(err?.message);
        message.error("user already exists with this email or Contact");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (authValue?.token) {
    if (authValue?.currUser?.default_branch) {
      return <Navigate to={"/"} />;
    }
    return <Navigate to={"/select-office"} />;
  }

  let divisor_for_minutes = seconds % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);
  let divisor_for_seconds = divisor_for_minutes % 60;
  let second = Math.ceil(divisor_for_seconds);

  return (
    <div className="th-flex-center" style={{ height: "100vh" }}>
      {!register ? (
        <Card className="" style={{ minWidth: "25.33rem", padding: "1rem" }}>
          <h3>Sign In</h3>
          <p className="text-secondary pt-1">
            {!loginOTP ? "Login and password" : "With Contact"}
          </p>
          {!loginOTP ? (
            <Form
              name="loginForm"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Contact"
                name="contact"
                rules={[
                  { required: true, message: "Please input your contact!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-100"
                  loading={loginLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Form
              name="loginForm"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Contact"
                name="contact"
                rules={[
                  { required: true, message: "Please input your contact!" },
                ]}
              >
                <Input />
              </Form.Item>
              {OTPRecieved && (
                <Form.Item
                  label="otp"
                  name="otp"
                  rules={[
                    { required: true, message: "Please input your otp!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-100"
                  loading={loginLoading}
                >
                  {loginOTP
                    ? OTPRecieved
                      ? "Login"
                      : "Get OTP"
                    : !authValue?.loginLoading
                    ? "Login"
                    : "Logging in..."}
                </Button>
              </Form.Item>
            </Form>
          )}
          <div
            className="th-pointer"
            onClick={() => {
              setLoginOTP((prevState) => !prevState);
              setOTPRecieved(false);
            }}
          >
            Want to sign in with <span>{!loginOTP ? "OTP" : "password"}</span> ?
          </div>
          {OTPRecieved && loginOTP && (
            <div className="col-md-12">
              <div className="d-flex justify-content-between align-items-center pl-0 mt-3">
                <Button
                  type="primary"
                  disabled={seconds > 0}
                  onClick={resendOTP}
                  htmlType="submit"
                  className="w-50"
                  loading={loginLoading}
                >
                  Resend OTP
                </Button>
                {seconds > 0 && (
                  <div className="font-size-16">
                    {minutes}:{second < 10 ? "0" + second : second}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Divider />
            <p>Don't have an account ?</p>
            <Button className="w-100" onClick={() => setRegister(true)}>
              Register for new account
            </Button>
          </div>
        </Card>
      ) : (
        <div>
          <Card style={{ minWidth: "25.33rem", padding: "1rem" }}>
            <h3 className="mb-1">Register</h3>
            <Form
              name="registerForm"
              initialValues={{ remember: true }}
              onFinish={onRegister}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input your contact!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your contact!" },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Contact"
                name="contact"
                rules={[
                  { required: true, message: "Please input your contact!" },
                  {
                    pattern: /^\d{10}$/,
                    message: "Please enter a valid 10-digit number",
                  },
                  { len: 10, message: "Contact number must be 10 digits long" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-100"
                  loading={loginLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <div
              className="th-pointer"
              onClick={() => {
                setRegister(false);
              }}
            >
              Login
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
