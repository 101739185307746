/** @format */

import {
  DashboardOutlined,
  UsergroupAddOutlined,
  InteractionOutlined,
  SettingOutlined,
  HistoryOutlined,
  UserOutlined,
  BankOutlined,
  FileOutlined,
  LeftOutlined,
} from "@ant-design/icons";
export const menu = [
  {
    icon: <DashboardOutlined />,
    label: "Dashboard",
    route: "/",
    key: 1,
    roles: [4, 7, 8],
  },
  {
    icon: <UsergroupAddOutlined />,
    label: "Office",
    route: "/manage-office",
    key: 2,
    roles: [7, 8],
  },
  {
    icon: <InteractionOutlined />,
    label: "Reception",
    roles: [6, 7, 8],
    key: 3,
    children: [
      {
        icon: <InteractionOutlined />,
        label: "Equeue",
        route: "/pause-equeue",
        key: 3.1,
        roles: [6, 7, 8],
      },
      {
        icon: <InteractionOutlined />,
        label: "Manage Equeue",
        route: "/equeue",
        key: 3.2,
        roles: [6, 7, 8],
      },
    ],
  },
  {
    icon: <UserOutlined />,
    label: "OPD",
    roles: [4, 7, 8],
    key: 4,
    children: [
      {
        icon: <UserOutlined />,
        label: "Patient Profile",
        route: "/patient-profile",
        roles: [4, 7, 8],
        key: 4.1,
      },
      {
        icon: <HistoryOutlined />,
        label: "OPD Register",
        route: "/opd-register",
        roles: [4, 7, 8],
        key: 4.2,
      },
    ],
  },
  {
    icon: <DashboardOutlined />,
    label: "Admin Dashboard",
    route: "/admin-dashboard",
    roles: [1],
    key: 5,
  },
  {
    icon: <DashboardOutlined />,
    label: "Drug List",
    route: "/drug-list",
    roles: [1],
    key: 6,
  },
  {
    icon: <DashboardOutlined />,
    label: "Requested Drug",
    route: "/requested-drug",
    roles: [1],
    key: 8,
  },
  {
    icon: <i className="fas fa-rupee-sign"></i>,
    label: "Payments ",
    roles: [7, 8],
    key: 9,
    children: [
      {
        icon: <i className="fas fa-rupee-sign"></i>,
        label: "Transactions",
        route: "/billing",
        roles: [7, 8],
        key: 9.1,
      },
      {
        icon: <i className="fas fa-rupee-sign"></i>,
        label: "Settlements",
        route: "/pay-out",
        roles: [7, 8],
        key: 9.2,
      },
    ],
  },
  {
    icon: <SettingOutlined />,
    label: "Settings",
    route: "/config/doctor-profile",
    roles: [4, 7, 8],
    className: "d-block d-md-none",
    key: 10,
  },
];

export const settingsMenu = [
  {
    icon: <LeftOutlined />,
    label: "Go to Dashboard",
    route: "/",
    roles: [4, 7, 8],
    key: 1,
  },
  {
    icon: <BankOutlined />,
    label: "Office",
    roles: [7, 8],
    key: 11,
    children: [
      {
        icon: <BankOutlined />,
        label: "Office Profile",
        route: "/config/office-profile",
        roles: [7, 8],
        key: 11.1,
      },
      {
        icon: <BankOutlined />,
        label: "Office Details",
        route: "/config/office-details",
        roles: [7, 8],
        key: 11.2,
      },
      {
        icon: <BankOutlined />,
        label: "Basic Details",
        route: "/config/basic-details",
        roles: [7, 8],
        key: 11.3,
      },
    ],
  },
  {
    icon: <UserOutlined />,
    label: "Doctor Profile",
    route: "/config/doctor-profile",
    roles: [4, 7],
    key: 12,
  },
  {
    icon: <FileOutlined />,
    label: "Prescription Config",
    route: "/config/prescription",
    roles: [4, 7],
    key: 13,
  },
];
