import { message, notification } from "antd";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useContext, useReducer, useState } from "react";

const AuthContext = createContext();

const dataReducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      let { payload } = action;
      localStorage.setItem(payload.key, JSON.stringify(payload.value));
      return { ...state, [payload.key]: payload.value };
    default:
      return {};
  }
};

const AuthProvider = ({ children }) => {
  const localAuthToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  const localCurrentUser = JSON.parse(localStorage.getItem("user"));

  const [token, setToken] = useState(localAuthToken);
  const [currUser, setCurrUser] = useState(localCurrentUser);
  const [changePassword, setChangePassword] = useState(false);

  const [loginLoading, setLoginLoading] = useState(false);

  const getCurrentUser = async (authToken) => {
    try {
      const localToken = document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      const currentUserRes = await axios.get(`apiV1/current-user/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${authToken ?? localToken}`,
        },
      });
      return currentUserRes;
    } catch (error) {
      console.log("getCurrentUser error", error?.response?.data?.message);
      notification.error({
        message:
          error?.response?.data?.message?.toString() ?? "Something went wrong!",
      });
      return;
    }
  };

  const loginHandler = async (values) => {
    const formData = new FormData();
    formData.append("contact", values?.contact);
    formData.append("password", values?.password);
    setLoginLoading(true);
    try {
      const loginResponse = await axios.post(`/token/`, formData);
      const currUserResponse = await getCurrentUser(
        loginResponse?.data?.access
      );
      if (currUserResponse == undefined || !currUserResponse) {
        return;
      }
      setToken(loginResponse?.data?.access);
      setCurrUser(currUserResponse?.data);
      if (loginResponse?.data?.access && currUserResponse) {
        document.cookie = `token=${loginResponse?.data?.access}; path=/`;
      }
      if (currUserResponse?.data) {
        localStorage.setItem("user", JSON.stringify(currUserResponse?.data));
        if (currUserResponse?.data?.master_data) {
          localStorage.setItem(
            "selectedDoctor",
            JSON.stringify(currUserResponse?.data?.master_data?.id ?? "")
          );
          localStorage.setItem(
            "selectedDoctorDetails",
            JSON.stringify(currUserResponse?.data?.master_data)
          );
        }
        if (currUserResponse?.data?.default_branch) {
          let branchDetails = currUserResponse?.data?.default_branch;
          //ADDING SERVICES CHARGES
          if (currUserResponse?.data?.service_charges) {
            branchDetails.service_charges =
              currUserResponse?.data?.service_charges;
          }
          //ADDING DEFAULT SELECTED  BRANCH
          localStorage.setItem(
            "selectedBranch",
            JSON.stringify(branchDetails?.id ?? "")
          );
          localStorage.setItem(
            "selectedBranchDetails",
            JSON.stringify(branchDetails)
          );
        }
      }
      if (currUserResponse) {
        if (currUserResponse?.data?.roles?.id == 6) {
          window.location.replace("/pause-equeue");
        } else window.location.reload();
      }
    } catch (error) {
      notification.error({
        message:
          error?.response?.data?.message ??
          error?.response?.data?.detail ??
          "Something went wrong!",
      });
    } finally {
      setLoginLoading(false);
    }
  };

  const refetchCurrentUser = async () => {
    try {
      const accessToken = document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      const currUserResponse = await getCurrentUser(accessToken);
      setToken(accessToken);
      setCurrUser(currUserResponse?.data);

      if (accessToken) {
        document.cookie = `token=${accessToken}; path=/`;
      }
      if (currUserResponse?.data) {
        localStorage.setItem("user", JSON.stringify(currUserResponse?.data));
        if (currUserResponse?.data?.master_data) {
          localStorage.setItem(
            "selectedDoctor",
            JSON.stringify(currUserResponse?.data?.master_data?.id ?? "")
          );
          localStorage.setItem(
            "selectedDoctorDetails",
            JSON.stringify(currUserResponse?.data?.master_data)
          );
        }
        if (currUserResponse?.data?.default_branch) {
          let branchDetails = currUserResponse?.data?.default_branch;
          //ADDING SERVICES CHARGES
          if (currUserResponse?.data?.service_charges) {
            branchDetails.service_charges =
              currUserResponse?.data?.service_charges;
          }
          //ADDING DEFAULT SELECTED  BRANCH
          localStorage.setItem(
            "selectedBranch",
            JSON.stringify(branchDetails?.id ?? "")
          );
          localStorage.setItem(
            "selectedBranchDetails",
            JSON.stringify(branchDetails)
          );
        }
      }
      window.location.reload();
    } catch (error) {
      notification.error({
        message:
          error?.response?.data?.message ??
          error?.response?.data?.detail ??
          "Something went wrong!",
      });
    } finally {
      setLoginLoading(false);
    }
  };

  const otpHandler = async (value) => {
    setLoginLoading(true);
    try {
      // const loginResponse = await axios.post(`/token/`, value);
      const currUserResponse = await getCurrentUser(value);
      setToken(value);
      setCurrUser(currUserResponse?.data);
      message.success("Successfully logged in!");

      if (currUserResponse?.data) {
        localStorage.setItem("user", JSON.stringify(currUserResponse?.data));
        if (currUserResponse?.data?.master_data) {
          localStorage.setItem(
            "selectedDoctor",
            JSON.stringify(currUserResponse?.data?.master_data?.id ?? "")
          );
          localStorage.setItem(
            "selectedDoctorDetails",
            JSON.stringify(currUserResponse?.data?.master_data)
          );
        }
        if (currUserResponse?.data?.default_branch) {
          let branchDetails = currUserResponse?.data?.default_branch;
          //ADDING SERVICES CHARGES
          if (currUserResponse?.data?.service_charges) {
            branchDetails.service_charges =
              currUserResponse?.data?.service_charges;
          }
          //ADDING DEFAULT SELECTED  BRANCH
          localStorage.setItem(
            "selectedBranch",
            JSON.stringify(branchDetails?.id ?? "")
          );
          localStorage.setItem(
            "selectedBranchDetails",
            JSON.stringify(branchDetails)
          );
        }
      }
      document.cookie = `token=${value}; path=/`;
      window.location.reload();
    } catch (error) {
      notification.error({
        message:
          error?.response?.data?.message ??
          error?.response?.data?.detail ??
          "Something went wrong!",
      });
    } finally {
      setLoginLoading(false);
    }
  };

  const logoutHandler = () => {
    setToken("");
    setCurrUser(null);
    localStorage.clear();
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };
  const changePasswordHandler = () => {
    setChangePassword(!changePassword);
  };

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      logoutHandler();
    }
  }

  const [dataState, dispatch] = useReducer(dataReducer, currUser);

  return (
    <AuthContext.Provider
      value={{
        token,
        currUser,
        loginHandler,
        logoutHandler,
        otpHandler,
        refetchCurrentUser,
        loginLoading,
        changePassword,
        changePasswordHandler,
        dispatch,
        dataState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
