import { Select, message, Input, Button, Drawer, Space, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { useData } from "../../../../../../context/data-context";
import { useAuth } from "../../../../../../context/auth-context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { SettingOutlined, CloseOutlined } from "@ant-design/icons";
function Branch({ width }) {
  const authValue = useAuth();
  const dataValue = useData();
  const navigate = useNavigate();
  const storedData = dataValue?.dataState;
  const { selectedBranchDetails } = storedData;
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(
    storedData?.selectedBranch
  );
  const [branchLoading, setBranchLoading] = useState(false);
  const [branchDrawer, setBranchDrawer] = useState(false);
  useEffect(() => {
    fetchBranchList();
  }, []);

  const fetchBranchList = () => {
    setBranchLoading(true);
    axios
      .get("apiV1/user-branch/")
      .then((res) => {
        setBranchList(res.data);
        if (storedData?.selectedBranch) {
          setSelectedBranch(storedData?.selectedBranch);
        } else {
          if (res.data[0]) {
            setSelectedBranch(res.data[0]?.id);
            dataValue.dispatch({
              type: "SET_STATE",
              payload: {
                key: "selectedBranch",
                value: res.data[0]?.id,
              },
            });
            dataValue.dispatch({
              type: "SET_STATE",
              payload: {
                key: "selectedBranchDetails",
                value: res.data[0],
              },
            });
          }
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setBranchLoading(false);
      });
  };

  const handleBranch = (branch) => {
    const currUserData = authValue.currUser;
    dataValue.dispatch({
      type: "SET_STATE",
      payload: {
        key: "globalLoading",
        value: true,
      },
    });
    axios
      .post(`/apiV1/change-branch/`, {
        current_branch: branch?.id,
      })
      .then((res) => {
        let updatedCurrUserData = {
          ...currUserData,
          roles: branch?.roles,
        };
        setBranchDrawer(false);
        setSelectedBranch(branch?.id);
        dataValue.dispatch({
          type: "SET_STATE",
          payload: {
            key: "selectedBranch",
            value: branch?.id,
          },
        });
        dataValue.dispatch({
          type: "SET_STATE",
          payload: {
            key: "selectedBranchDetails",
            value: branch,
          },
        });
        dataValue.dispatch({
          type: "SET_STATE",
          payload: {
            key: "globalLoading",
            value: false,
          },
        });
        authValue.dispatch({
          type: "SET_STATE",
          payload: {
            key: "user",
            value: updatedCurrUserData,
          },
        });
        if (updatedCurrUserData?.roles?.id == 6) {
          window.location.replace("/pause-equeue");
        } else {
          window.location.replace("/");
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        dataValue.dispatch({
          type: "SET_STATE",
          payload: {
            key: "globalLoading",
            value: false,
          },
        });
      });
  };

  return (
    <>
      <Button
        className="mr-3 th-pointer"
        onClick={() => {
          setBranchDrawer(true);
        }}
        icon={<i className="fas fa-random th-primary" />}
      >
        {selectedBranchDetails?.establishment_name}
      </Button>
      <Drawer
        bodyStyle={{ padding: 0 }}
        title={
          <div className="d-flex justify-content-between align-items-center">
            <div>My Practice Offices</div>
            <div className="d-flex align-items-center">
              <Button
                className="th-primary"
                type="text"
                icon={<SettingOutlined />}
                onClick={() => {
                  navigate("/select-office");
                }}
              >
                Manage
              </Button>
              <CloseOutlined
                className="th-pointer"
                onClick={() => {
                  setBranchDrawer(false);
                }}
              />
            </div>
          </div>
        }
        closeIcon={null}
        open={branchDrawer}
        onClose={() => {
          setBranchDrawer(false);
        }}
      >
        {branchList?.map((branch, ind) => (
          <div
            className={
              "card d-flex flex-row justify-content-between align-items-center py-2 th-pointer px-2 " +
              (selectedBranch == branch?.id ? " selected-branch" : "")
            }
            onClick={() => {
              if (selectedBranch !== branch?.id) handleBranch(branch);
            }}
          >
            <div className="d-flex align-items-center">
              <div className="">
                <Avatar
                  shape="square"
                  src={
                    branch?.page_logo ??
                    `https://imgs.search.brave.com/qWzc_64LAnLo8qdZOPmXpr1Q7fWwQ0gVSrRG_IB8j98/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzEwLzQ1LzM5/LzM2MF9GXzIxMDQ1/MzkyNV9zcEhkTEhV/Y0RKUzc2b1dLekZw/NG1RZUNLVzhXaXNF/Ui5qcGc`
                  }
                />
              </div>
              <div className="ml-2">
                <div className="th-12">
                  {branch?.establishment_name} &#8226; {branch?.branch}
                </div>
                <div className="th-10">{branch?.owner_details_address}</div>
              </div>
            </div>
            {selectedBranch == branch?.id && (
              <div className="th-14">
                <i className="fas fa-check-circle text-success"></i>
              </div>
            )}
          </div>
        ))}
      </Drawer>
    </>
  );
}

export default Branch;
